import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Box, Image, Link, Text } from "@chakra-ui/react";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <Box textAlign="center" bg="black">
      <Box
        as="header"
        minH="100vh"
        display="flex"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        color="white"
      >
        <Text fontWeight="bold" fontSize="2.5em">Boring Tools For Disciplined Crypto Accumulation</Text>

        <Text mt="5em">Hibernating</Text>
          <Link href="https://twitter.com/BTFDCA_finance" isExternal fontSize="1em" textDecoration="none">
            Follow&nbsp;🐦&nbsp;for updates & inquiries
          </Link>
      </Box>
    </Box>
  </React.StrictMode>
);
